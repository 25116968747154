import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';

import MainHeader from './MainHeader';
import NavLinks from './NavLinks';
import Backdrop from '../UIElements/Backdrop';
import { AuthContext } from '../../context/auth-context';
import './MainNavigation.css';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const auth = useContext(AuthContext);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  }

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  }

  const buttons = [
    //running into a problem where the button only links when you click on the text, not the outer part of the button
    <Link to={`/`}><Button>Home</Button></Link>,
    <Link to={`/peoplepage`} ><Button>Player Search</Button></Link>,
    <Link to={`/leaderboard`}><Button>All-Time Leaderboard</Button></Link>,
    <Link to={`/profile`} ><Button>Your Profile</Button></Link>,
    <Link to={`/settings`} ><Button>Account Settings</Button></Link>,
    <Link to={`/about`} ><Button >About Casual Wager</Button></Link>,
    <Button color = "error" onClick={auth.logout}> LOGOUT </Button>
  ];


  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <Drawer
        anchor ='right'
        open={drawerIsOpen}
        onClick={closeDrawerHandler}
      >

      <ButtonGroup
          color ="primary"
          orientation="vertical"
          aria-label="vertical contained button group"
          variant="text"
        >
          {buttons}
      </ButtonGroup>

          {// todo these items can be put into a component to improve readability

          <ul>
            

            {auth.role==="ADMIN" && (
              <li>
                <Link to={`/admin`} >ADMIN</Link>
              </li>
            )}
         
          
          </ul>
        }
      </Drawer>

      <MainHeader>
        
        {auth.isLoggedIn && (
          <button
            className="main-navigation__menu-btn grey lighten-3"
            onClick={openDrawerHandler}
          >
            <i className="material-icons hamburger waves-effect">menu</i>
          </button>
        )}
        <div className="main-navigation__title">
          <Link to="/">
            <img src="\images\cw-v1.png" alt="logo" width="140" height="auto" />
          </Link>
        </div>
        <nav className="main-navigation__header-nav z-depth-0">
          <NavLinks />
        </nav>
      </MainHeader>
    </React.Fragment>
  );

};

export  default MainNavigation;