import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';

import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import GamesList from '../components/GamesList';
import PendingFriendList from '../components/PendingFriendList';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useSSEEvent } from '../../shared/hooks/sse-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { BetContext } from '../../shared/context/bet-context';
import './Home.css';
import { yellow } from '@mui/material/colors';

const Home = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedPlayerGames, setPlayerGames] = useState();
  const [playerFriends, setPlayerFriends] = useState();
  const [announcement, setAnnouncement] = useState();
  const [notification, setNotification] = useState("");
  const [notificationOpen, setNotificationOpen] = useState(false);
  const auth = useContext(AuthContext);
  const bet = useContext(BetContext);
  bet.creator = null;
  const {startSSE, stopSSE, setHomeChange} = useSSEEvent();
  const navigate = useNavigate();

  const retrieveGames = useCallback( async ()  => {
    if(auth.userId){
      try {
        const user = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/users/id/${auth.userId}`
        );

        const newFriends = user.user.friends.filter(friend => friend.status==="pending");
        setPlayerFriends(newFriends);

        const creatorData = await sendRequest(
          `${process.env.REACT_APP_API_HOST}/games/player/${auth.userId}`
        );
        setPlayerGames(creatorData.games);

        if(!auth.hasSeenAnnouncement){
          const anAnnouncement = await sendRequest(
            `${process.env.REACT_APP_API_HOST}/announcements/latest`
          );
          setAnnouncement(anAnnouncement.announcement.message);
        } 
      } catch (err) {}
    }
  },[auth.userId, sendRequest, auth.hasSeenAnnouncement])

  const onHomeChange = (text) =>{
    setNotification(text);
    setNotificationOpen(true);
    setTimeout(() => {
      retrieveGames();
    }, 1500);
  }

  startSSE();
  setHomeChange(onHomeChange);

  useEffect(() => {
    const fetchGames = async () => {
      retrieveGames();
    };
    fetchGames();
  }, [sendRequest, retrieveGames]);

  
  useEffect(() => {
    return () => {
      stopSSE("home");
    }
    // eslint-disable-next-line
  }, [])

  const startNewGame = () => {
    bet.betText = "";
    bet.topicId=null;
    
    navigate(`/addbetuser/`);
  }
  
  const clearAnnouncement = async () => {
    setAnnouncement("");
    auth.hasSeenAnnouncement = true;
    // send has seen announcement to backend
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/clearannouncement/${auth.userId}`
      );
    } catch(err){}
  }


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      {!isLoading && announcement && (
        <React.Fragment>
          <Grid container>
            <Grid item xs={11} sx={{ bgcolor: yellow[500] }} >
              <Typography variant="h6" color="black">
                {announcement}
              </Typography>
            </Grid>
            <Grid item xs={1}><Button variant="contained" onClick={clearAnnouncement}>X</Button></Grid>
          </Grid>
        </React.Fragment>
      )}

      

      {!isLoading && loadedPlayerGames && (        
        <React.Fragment>
          {!isLoading && playerFriends  && (
            <PendingFriendList  pending={playerFriends} />
          )}

          <GamesList items={loadedPlayerGames} header={true}/>

          <Button sx={{float: 'right'}} variant="contained" color='secondary' onClick={startNewGame}>
            New Game
          </Button>
          
        </React.Fragment>
      )}

      <Snackbar
        open={notificationOpen}
        onClose={() => { setNotificationOpen(false);}}
        autoHideDuration={6000}
        message={notification}
      />

    </React.Fragment>
  );
};

export default Home;
