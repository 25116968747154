import React, { useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import { AuthContext } from '../../shared/context/auth-context';

import { useHttpClient } from '../../shared/hooks/http-hook'

const UserItem = ({user}) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [friendStatus, setFriendStatus] = useState('');

  useEffect(() => {
    if(auth.userId===user._id){setFriendStatus('self');}
    else{
      const friendCheck = user.friends.find(friend => friend.friend===auth.userId);
      if(friendCheck){setFriendStatus(friendCheck.status);}
    }
  },[auth.userId, user]);
  

  const requestFriend = async () => {
    try {
      const outstring = JSON.stringify({
        player: auth.userId,
        friend: user._id, 
      });
      await sendRequest(
        `${process.env.REACT_APP_API_HOST}/users/friendrequest`,
            'POST',
            `${outstring}`,
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
      );
      setNotificationOpen(true);
      setFriendStatus('pending');
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <Snackbar
      open={notificationOpen}
      onClose={() => { setNotificationOpen(false);}}
      autoHideDuration={6000}
      message={"Your friend request has been sent!"}
      />
      <Grid container style={{ minwidth:300, maxWidth: 350 }}>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={5.5}>
          <Link to={`/profile/${user.username}`} style={{ float: 'left'}}><b>{user.username}</b></Link>
        </Grid>
        <Grid item xs={6} style={{ float: 'center'}}>
          {friendStatus!=='' && <b>{friendStatus}</b>}
          {friendStatus==='' && (
          <Button variant="contained" color='secondary' onClick={requestFriend}>Request Friend</Button>
          )}
        </Grid>
        <Grid item xs={6}>
          <b>Started:</b> {dayjs(user.createddate).format('MM/DD/YYYY HH:mm')} 
        </Grid>
        <Grid item xs={6}>  
          <b>Last Seen:</b> {dayjs(user.lastseendate).format('MM/DD/YYYY HH:mm')}
        </Grid>
        <Grid item xs={12}><hr/>
        </Grid>
      
      </Grid>
    </React.Fragment>
  )
  
};

export default UserItem;
