import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';

import { AuthContext } from '../../../shared/context/auth-context';
import './NavLinks.css';



const NavLinks = props => {
  const auth = useContext(AuthContext);
  

  return (
    <React.Fragment>
       
      {auth.isLoggedIn && (

            <NavLink to={`/profile`}>
              <Avatar 
                alt={auth.username}
                src={auth.userImage}
                sx={{ width: 40, height: 40 }}
              />
            </NavLink>
          
      )}
     
     </React.Fragment>
  );
};

export default NavLinks;
